<template>
  <div class="container">
    <h1>分销统计</h1>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="Name" label="分销商名称" />
        <el-table-column prop="CountToday" label="今日购券">
          <template #default="scope">
            {{ scope.row.CountToday }}
            <el-button v-if="scope.row.CountToday>0" type="primary" size="mini" icon="el-icon-takeaway-box"
              @click="handleDetail(scope.row.ID,1)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="CountWeek" label="本周购券">
          <template #default="scope">
            {{ scope.row.CountWeek }}
            <el-button v-if="scope.row.CountWeek>0" type="primary" size="mini" icon="el-icon-takeaway-box"
              @click="handleDetail(scope.row.ID,2)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="CountMonth" label="本月购券">
          <template #default="scope">
            {{ scope.row.CountMonth }}
            <el-button v-if="scope.row.CountMonth>0" type="primary" size="mini" icon="el-icon-takeaway-box"
              @click="handleDetail(scope.row.ID,3)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <detail :distributorId="curDistributorId" :type="curType" v-model:showDialog="showDetail" />
  </div>
</template>

<script>
  import {
    getDistributorStatistics
  } from "@/api/distributor"
  import Detail from "./statisticsDetail"

  export default {
    components: {
      Detail
    },
    data() {
      return {
        loading: false,
        items: [],
        showDetail: false,
        curDistributorId: 0,
        curType: 0
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getDistributorStatistics()
          .then(res => {
            if (res.success) {
              this.items = res.items
            }
          })
      },
      handleDetail(id, type) {
        this.curDistributorId = id
        this.curType = type
        this.showDetail = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
